import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    fontFamily: 'Roboto',
    body2: {
      fontWeight: 800,
    },
  },
  components: componentDefaults,
});

export const sampleOTATheme = responsiveFontSizes(
  createTheme(theme, {
    cssVariables: { cssVarPrefix: '' },
    palette: {
      primary: theme.palette.augmentColor({
        color: {
          main: 'rgb(253, 219, 50)',
        },
        name: 'primary',
      }),
      secondary: theme.palette.augmentColor({
        color: {
          main: '#307100',
        },
        name: 'secondary',
      }),
      error: {
        main: '#f23221',
        light: '#e66b67',
        dark: '#dd2825',
      },
    },
  })
);
