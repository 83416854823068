export enum Partner {
  AirCanada = '4ef186ee-e6b5-467d-bfb2-0d31c78ea5db',
  HtsAirline = 'hts-airline',
  VirginAustralia = 'bcd0b2a1-d2d1-4a9e-ad3c-b5122b70dc58',
  Volaris = '00e30494-bdfb-4815-8a39-f9ec3e1e5caf',
  AirAsia = '72f970df-78a4-463c-b455-a8f77a3eb37c',
  Flair = 'a3f39f5f-1b09-46a9-9243-9f7b8160a8ce',
  Jazeera = '25c4b808-bd59-44be-903c-1ce0b65170a6',

  /* OTA */
  HTS = '23459807-1a9a-4227-a7aa-226e3c5552d1',
  SampleOTA = '111bd347-ddd8-42c3-aea1-85c29b24a8bd',
  Super = '2a60f37a-f83b-4b9a-8cc3-4a93688d2daa',
  Nuitee = '416ceb73-5dbe-4518-8d43-5cfda5ad0b8a',
  Flyadeal = '7a048b2f-df0e-4a36-b34a-73eb1a19836d',
}
