import { CfarStatus, PayoutMethod } from '@hts/partner-airlines-api-client';
import {
  Container,
  Link,
  Step as StepperStep,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useReducer, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { BookingSummary } from '../products/cfar/components/BookingSummary';
import { RecipientForm } from '../products/cfar/components/RecipientForm/RecipientForm';
import { Payment, Recipient } from '../products/cfar/components/Payment';
import { Confirmation } from '../products/cfar/components/Confirmation';
import { useSuspenseQuery } from '@tanstack/react-query';
import { exerciseOptions } from '../products/cfar/queries/exerciseOptions';
import { sessionOptions } from '../products/cfar/queries/sessionOptions';
import { Loading } from '../common/components/Loading';
import { ErrorCode } from '../common/constants/errorCode';
import { PaymentReview } from '../products/cfar/components/PaymentReview';
import { HWTransferMethod } from '../common/components/hooks/useHyperwallet';
import {
  PayoutFlowType,
  stepReducer,
  createInitialState,
  ExerciseStep,
  Step,
} from '../products/cfar/components/reducers/stepReducer';
import { setDatadogContext } from '../products/cfar/utilities/setDatadogContext';
import { ErrorComponent } from '../common/components/ErrorComponent';
import { Payout } from '../products/cfar/types';
import { useCfarStore } from '../products/cfar/store';
import { enUS } from 'date-fns/locale/en-US';
import { DATE_FNS_LOCALES, SupportedLocale } from '../i18n/constants';

export const Route = createFileRoute('/_authenticated/exercise')({
  component: () => <Exercise />,
  pendingComponent: Loading,
  loader: async ({ context: { queryClient } }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }
    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );

    setDatadogContext({
      exercise_id: exercise.id,
      contract_id: exercise.contractId,
    });

    return { exercise };
  },
  errorComponent: ErrorComponent,
});

function Exercise() {
  const { sessionId } = useCfarStore();
  const {
    data: { contactFormUrl },
  } = useSuspenseQuery(sessionOptions(sessionId));

  const { exercise } = Route.useLoaderData();
  const { t, i18n } = useTranslation();
  const isHyperwalletFlow =
    exercise.payoutMethod === PayoutMethod.PayoutProvider;
  const flowType = isHyperwalletFlow
    ? PayoutFlowType.HyperwalletFlow
    : PayoutFlowType.OriginalFormOfPaymentFlow;

  const [dateFnsLocale, setDateFnsLocale] = useState(enUS);

  useEffect(() => {
    DATE_FNS_LOCALES[i18n.language as SupportedLocale]().then((localeData) => {
      setDateFnsLocale(localeData[i18n.language]);
    });
  }, [i18n.language]);

  const [{ current: currentStep }, dispatch] = useReducer(
    stepReducer,
    createInitialState(flowType)
  );

  if (exercise.status === CfarStatus.Confirmed) {
    return (
      <Container sx={{ padding: [3, 4] }}>
        <Trans
          i18nKey={'CFAR_EXERCISE_FLOW.CONTRACT_ALREADY_REFUNDED'}
          components={[
            <Link href={contactFormUrl} target="_blank" rel="noopener" />,
          ]}
        />
      </Container>
    );
  }

  const toNextStep = (step: ExerciseStep) => {
    dispatch({
      type: 'next_step',
      to: step,
    });
  };

  const toPreviousStep = () => {
    dispatch({ type: 'previous_step' });
  };

  const summaryStep = {
    label: t('CFAR_EXERCISE_FLOW.STEP.FIRST'),
    step: Step.Summary,
    content: <BookingSummary stepForward={toNextStep} />,
  };

  const createRecipientStep = {
    label: t('CFAR_EXERCISE_FLOW.STEP.SECOND'),
    step: Step.CreateRecipient,
    content: (
      <RecipientForm
        stepForward={(recipient: Recipient) => {
          toNextStep({
            step: Step.CreateTransferMethod,
            recipient,
          });
        }}
      />
    ),
  };

  const createTransferMethodStep = {
    label: t('CFAR_EXERCISE_FLOW.STEP.THIRD'),
    step: Step.CreateTransferMethod,
    content: currentStep.step === Step.CreateTransferMethod && (
      <Payment
        stepForward={(transferMethod: HWTransferMethod) => {
          toNextStep({ step: Step.ConfirmPayout, transferMethod });
        }}
        recipient={currentStep.recipient}
      />
    ),
  };

  const confirmPayoutStep = {
    label: t('CFAR_EXERCISE_FLOW.STEP.FOURTH'),
    step: Step.ConfirmPayout,
    content: currentStep.step === Step.ConfirmPayout && (
      <PaymentReview
        transferMethod={currentStep.transferMethod}
        stepForward={(payout: Payout) =>
          toNextStep({ step: Step.Confirmation, payout })
        }
        stepBack={toPreviousStep}
      />
    ),
  };

  const confirmationStep = {
    label: t('CFAR_EXERCISE_FLOW.STEP.FIFTH'),
    step: Step.Confirmation,
    content: currentStep.step === Step.Confirmation && (
      <Confirmation
        isActive={currentStep.step === Step.Confirmation}
        payout={currentStep.payout}
      />
    ),
  };

  const steps = isHyperwalletFlow
    ? [
        summaryStep,
        createRecipientStep,
        createTransferMethodStep,
        confirmPayoutStep,
        confirmationStep,
      ]
    : [summaryStep, confirmationStep];

  const activeStep = steps.findIndex(({ step }) => step === currentStep.step);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({ label, content, step }) => (
          <StepperStep key={step}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <div data-testid={step}>
                {currentStep.step === step ? content : <Loading />}
              </div>
            </StepContent>
          </StepperStep>
        ))}
      </Stepper>
    </LocalizationProvider>
  );
}
