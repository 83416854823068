import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const akBrandColor = '#01ea6b';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 700,
    },
    fontFamily: 'OpenSans',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
          fontWeight: 800,
          borderRadius: '40px',
        },
        contained: {
          border: '1px solid',
          borderColor: '#000000',
        },
        text: {
          color: '#000000',
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#ffffff',
        navbarContrastText: '#000000',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: akBrandColor,
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const akTheme = responsiveFontSizes(createTheme(theme2));
