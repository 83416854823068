/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticateImport } from './routes/authenticate'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as V2AuthenticateImport } from './routes/v2/authenticate'
import { Route as V2AuthenticatedImport } from './routes/v2/_authenticated'
import { Route as AuthenticatedExerciseImport } from './routes/_authenticated.exercise'
import { Route as V2AuthenticatedSummaryImport } from './routes/v2/_authenticated.summary'
import { Route as V2AuthenticatedPaymentErrorImport } from './routes/v2/_authenticated.payment-error'
import { Route as V2AuthenticatedPaymentImport } from './routes/v2/_authenticated.payment'
import { Route as V2AuthenticatedCreateRecipientImport } from './routes/v2/_authenticated.create-recipient'
import { Route as V2AuthenticatedConfirmationImport } from './routes/v2/_authenticated.confirmation'
import { Route as V2AuthenticatedCardPayoutFormImport } from './routes/v2/_authenticated.card-payout-form'
import { Route as V2AuthenticatedBankTransferFormImport } from './routes/v2/_authenticated.bank-transfer-form'

// Create Virtual Routes

const V2Import = createFileRoute('/v2')()

// Create/Update Routes

const V2Route = V2Import.update({
  id: '/v2',
  path: '/v2',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticateRoute = AuthenticateImport.update({
  id: '/authenticate',
  path: '/authenticate',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const V2AuthenticateRoute = V2AuthenticateImport.update({
  id: '/authenticate',
  path: '/authenticate',
  getParentRoute: () => V2Route,
} as any)

const V2AuthenticatedRoute = V2AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => V2Route,
} as any)

const AuthenticatedExerciseRoute = AuthenticatedExerciseImport.update({
  id: '/exercise',
  path: '/exercise',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const V2AuthenticatedSummaryRoute = V2AuthenticatedSummaryImport.update({
  id: '/summary',
  path: '/summary',
  getParentRoute: () => V2AuthenticatedRoute,
} as any)

const V2AuthenticatedPaymentErrorRoute =
  V2AuthenticatedPaymentErrorImport.update({
    id: '/payment-error',
    path: '/payment-error',
    getParentRoute: () => V2AuthenticatedRoute,
  } as any)

const V2AuthenticatedPaymentRoute = V2AuthenticatedPaymentImport.update({
  id: '/payment',
  path: '/payment',
  getParentRoute: () => V2AuthenticatedRoute,
} as any)

const V2AuthenticatedCreateRecipientRoute =
  V2AuthenticatedCreateRecipientImport.update({
    id: '/create-recipient',
    path: '/create-recipient',
    getParentRoute: () => V2AuthenticatedRoute,
  } as any)

const V2AuthenticatedConfirmationRoute =
  V2AuthenticatedConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => V2AuthenticatedRoute,
  } as any)

const V2AuthenticatedCardPayoutFormRoute =
  V2AuthenticatedCardPayoutFormImport.update({
    id: '/card-payout-form',
    path: '/card-payout-form',
    getParentRoute: () => V2AuthenticatedRoute,
  } as any)

const V2AuthenticatedBankTransferFormRoute =
  V2AuthenticatedBankTransferFormImport.update({
    id: '/bank-transfer-form',
    path: '/bank-transfer-form',
    getParentRoute: () => V2AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/authenticate': {
      id: '/authenticate'
      path: '/authenticate'
      fullPath: '/authenticate'
      preLoaderRoute: typeof AuthenticateImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/exercise': {
      id: '/_authenticated/exercise'
      path: '/exercise'
      fullPath: '/exercise'
      preLoaderRoute: typeof AuthenticatedExerciseImport
      parentRoute: typeof AuthenticatedImport
    }
    '/v2': {
      id: '/v2'
      path: '/v2'
      fullPath: '/v2'
      preLoaderRoute: typeof V2Import
      parentRoute: typeof rootRoute
    }
    '/v2/_authenticated': {
      id: '/v2/_authenticated'
      path: '/v2'
      fullPath: '/v2'
      preLoaderRoute: typeof V2AuthenticatedImport
      parentRoute: typeof V2Route
    }
    '/v2/authenticate': {
      id: '/v2/authenticate'
      path: '/authenticate'
      fullPath: '/v2/authenticate'
      preLoaderRoute: typeof V2AuthenticateImport
      parentRoute: typeof V2Import
    }
    '/v2/_authenticated/bank-transfer-form': {
      id: '/v2/_authenticated/bank-transfer-form'
      path: '/bank-transfer-form'
      fullPath: '/v2/bank-transfer-form'
      preLoaderRoute: typeof V2AuthenticatedBankTransferFormImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/card-payout-form': {
      id: '/v2/_authenticated/card-payout-form'
      path: '/card-payout-form'
      fullPath: '/v2/card-payout-form'
      preLoaderRoute: typeof V2AuthenticatedCardPayoutFormImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/confirmation': {
      id: '/v2/_authenticated/confirmation'
      path: '/confirmation'
      fullPath: '/v2/confirmation'
      preLoaderRoute: typeof V2AuthenticatedConfirmationImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/create-recipient': {
      id: '/v2/_authenticated/create-recipient'
      path: '/create-recipient'
      fullPath: '/v2/create-recipient'
      preLoaderRoute: typeof V2AuthenticatedCreateRecipientImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/payment': {
      id: '/v2/_authenticated/payment'
      path: '/payment'
      fullPath: '/v2/payment'
      preLoaderRoute: typeof V2AuthenticatedPaymentImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/payment-error': {
      id: '/v2/_authenticated/payment-error'
      path: '/payment-error'
      fullPath: '/v2/payment-error'
      preLoaderRoute: typeof V2AuthenticatedPaymentErrorImport
      parentRoute: typeof V2AuthenticatedImport
    }
    '/v2/_authenticated/summary': {
      id: '/v2/_authenticated/summary'
      path: '/summary'
      fullPath: '/v2/summary'
      preLoaderRoute: typeof V2AuthenticatedSummaryImport
      parentRoute: typeof V2AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedExerciseRoute: typeof AuthenticatedExerciseRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedExerciseRoute: AuthenticatedExerciseRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface V2AuthenticatedRouteChildren {
  V2AuthenticatedBankTransferFormRoute: typeof V2AuthenticatedBankTransferFormRoute
  V2AuthenticatedCardPayoutFormRoute: typeof V2AuthenticatedCardPayoutFormRoute
  V2AuthenticatedConfirmationRoute: typeof V2AuthenticatedConfirmationRoute
  V2AuthenticatedCreateRecipientRoute: typeof V2AuthenticatedCreateRecipientRoute
  V2AuthenticatedPaymentRoute: typeof V2AuthenticatedPaymentRoute
  V2AuthenticatedPaymentErrorRoute: typeof V2AuthenticatedPaymentErrorRoute
  V2AuthenticatedSummaryRoute: typeof V2AuthenticatedSummaryRoute
}

const V2AuthenticatedRouteChildren: V2AuthenticatedRouteChildren = {
  V2AuthenticatedBankTransferFormRoute: V2AuthenticatedBankTransferFormRoute,
  V2AuthenticatedCardPayoutFormRoute: V2AuthenticatedCardPayoutFormRoute,
  V2AuthenticatedConfirmationRoute: V2AuthenticatedConfirmationRoute,
  V2AuthenticatedCreateRecipientRoute: V2AuthenticatedCreateRecipientRoute,
  V2AuthenticatedPaymentRoute: V2AuthenticatedPaymentRoute,
  V2AuthenticatedPaymentErrorRoute: V2AuthenticatedPaymentErrorRoute,
  V2AuthenticatedSummaryRoute: V2AuthenticatedSummaryRoute,
}

const V2AuthenticatedRouteWithChildren = V2AuthenticatedRoute._addFileChildren(
  V2AuthenticatedRouteChildren,
)

interface V2RouteChildren {
  V2AuthenticatedRoute: typeof V2AuthenticatedRouteWithChildren
  V2AuthenticateRoute: typeof V2AuthenticateRoute
}

const V2RouteChildren: V2RouteChildren = {
  V2AuthenticatedRoute: V2AuthenticatedRouteWithChildren,
  V2AuthenticateRoute: V2AuthenticateRoute,
}

const V2RouteWithChildren = V2Route._addFileChildren(V2RouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/exercise': typeof AuthenticatedExerciseRoute
  '/v2': typeof V2AuthenticatedRouteWithChildren
  '/v2/authenticate': typeof V2AuthenticateRoute
  '/v2/bank-transfer-form': typeof V2AuthenticatedBankTransferFormRoute
  '/v2/card-payout-form': typeof V2AuthenticatedCardPayoutFormRoute
  '/v2/confirmation': typeof V2AuthenticatedConfirmationRoute
  '/v2/create-recipient': typeof V2AuthenticatedCreateRecipientRoute
  '/v2/payment': typeof V2AuthenticatedPaymentRoute
  '/v2/payment-error': typeof V2AuthenticatedPaymentErrorRoute
  '/v2/summary': typeof V2AuthenticatedSummaryRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/exercise': typeof AuthenticatedExerciseRoute
  '/v2': typeof V2AuthenticatedRouteWithChildren
  '/v2/authenticate': typeof V2AuthenticateRoute
  '/v2/bank-transfer-form': typeof V2AuthenticatedBankTransferFormRoute
  '/v2/card-payout-form': typeof V2AuthenticatedCardPayoutFormRoute
  '/v2/confirmation': typeof V2AuthenticatedConfirmationRoute
  '/v2/create-recipient': typeof V2AuthenticatedCreateRecipientRoute
  '/v2/payment': typeof V2AuthenticatedPaymentRoute
  '/v2/payment-error': typeof V2AuthenticatedPaymentErrorRoute
  '/v2/summary': typeof V2AuthenticatedSummaryRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/_authenticated/exercise': typeof AuthenticatedExerciseRoute
  '/v2': typeof V2RouteWithChildren
  '/v2/_authenticated': typeof V2AuthenticatedRouteWithChildren
  '/v2/authenticate': typeof V2AuthenticateRoute
  '/v2/_authenticated/bank-transfer-form': typeof V2AuthenticatedBankTransferFormRoute
  '/v2/_authenticated/card-payout-form': typeof V2AuthenticatedCardPayoutFormRoute
  '/v2/_authenticated/confirmation': typeof V2AuthenticatedConfirmationRoute
  '/v2/_authenticated/create-recipient': typeof V2AuthenticatedCreateRecipientRoute
  '/v2/_authenticated/payment': typeof V2AuthenticatedPaymentRoute
  '/v2/_authenticated/payment-error': typeof V2AuthenticatedPaymentErrorRoute
  '/v2/_authenticated/summary': typeof V2AuthenticatedSummaryRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/authenticate'
    | '/exercise'
    | '/v2'
    | '/v2/authenticate'
    | '/v2/bank-transfer-form'
    | '/v2/card-payout-form'
    | '/v2/confirmation'
    | '/v2/create-recipient'
    | '/v2/payment'
    | '/v2/payment-error'
    | '/v2/summary'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/authenticate'
    | '/exercise'
    | '/v2'
    | '/v2/authenticate'
    | '/v2/bank-transfer-form'
    | '/v2/card-payout-form'
    | '/v2/confirmation'
    | '/v2/create-recipient'
    | '/v2/payment'
    | '/v2/payment-error'
    | '/v2/summary'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/authenticate'
    | '/_authenticated/exercise'
    | '/v2'
    | '/v2/_authenticated'
    | '/v2/authenticate'
    | '/v2/_authenticated/bank-transfer-form'
    | '/v2/_authenticated/card-payout-form'
    | '/v2/_authenticated/confirmation'
    | '/v2/_authenticated/create-recipient'
    | '/v2/_authenticated/payment'
    | '/v2/_authenticated/payment-error'
    | '/v2/_authenticated/summary'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AuthenticateRoute: typeof AuthenticateRoute
  V2Route: typeof V2RouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AuthenticateRoute: AuthenticateRoute,
  V2Route: V2RouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/authenticate",
        "/v2"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/exercise"
      ]
    },
    "/authenticate": {
      "filePath": "authenticate.tsx"
    },
    "/_authenticated/exercise": {
      "filePath": "_authenticated.exercise.tsx",
      "parent": "/_authenticated"
    },
    "/v2": {
      "filePath": "v2",
      "children": [
        "/v2/_authenticated",
        "/v2/authenticate"
      ]
    },
    "/v2/_authenticated": {
      "filePath": "v2/_authenticated.tsx",
      "parent": "/v2",
      "children": [
        "/v2/_authenticated/bank-transfer-form",
        "/v2/_authenticated/card-payout-form",
        "/v2/_authenticated/confirmation",
        "/v2/_authenticated/create-recipient",
        "/v2/_authenticated/payment",
        "/v2/_authenticated/payment-error",
        "/v2/_authenticated/summary"
      ]
    },
    "/v2/authenticate": {
      "filePath": "v2/authenticate.tsx",
      "parent": "/v2"
    },
    "/v2/_authenticated/bank-transfer-form": {
      "filePath": "v2/_authenticated.bank-transfer-form.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/card-payout-form": {
      "filePath": "v2/_authenticated.card-payout-form.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/confirmation": {
      "filePath": "v2/_authenticated.confirmation.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/create-recipient": {
      "filePath": "v2/_authenticated.create-recipient.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/payment": {
      "filePath": "v2/_authenticated.payment.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/payment-error": {
      "filePath": "v2/_authenticated.payment-error.tsx",
      "parent": "/v2/_authenticated"
    },
    "/v2/_authenticated/summary": {
      "filePath": "v2/_authenticated.summary.tsx",
      "parent": "/v2/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
