import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, OutlinedInput } from '@mui/material';
import { EXERCISE_COMMON_NAMESPACE } from '../locales/constants';

type InputProps = {
  onChange: (value: string) => void;
  error?: React.ReactNode;
};

const INPUT_LENGTH = 6;
const INPUTS_ARRAY = Array.from({ length: INPUT_LENGTH }).fill('');

const VERIFICATION_CODE_PATTERN = new RegExp('^[0-9]{6}$');

export const OTPInput = ({ onChange, error }: InputProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const { t } = useTranslation(EXERCISE_COMMON_NAMESPACE);
  const isSubmitted = useRef(false);

  const [value, setValue] = React.useState(INPUTS_ARRAY);

  const setValueAtIndex = (index: number, newValue: string) => {
    // ensure only one character is entered per input
    if (newValue.length > 1) {
      return;
    }

    setValue((prevValue) => {
      const newValueArray = [...prevValue];
      newValueArray[index] = newValue;
      return newValueArray;
    });
  };

  const targetInputAtIndex = (index: number) => {
    const inputs = containerRef.current?.querySelectorAll('input') || [];
    const targetInput = inputs[index];
    if (targetInput) {
      targetInput.focus();
      targetInput.select();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const focusedInput = event.target as HTMLInputElement;
    const currentIndex = Number(focusedInput.id);

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (currentIndex > 0) {
          targetInputAtIndex(currentIndex - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (currentIndex < INPUT_LENGTH - 1) {
          targetInputAtIndex(currentIndex + 1);
        }
        break;
      case 'Delete':
        event.preventDefault();
        setValueAtIndex(currentIndex, '');
        break;
      case 'Backspace':
        event.preventDefault();
        if (currentIndex > 0) {
          targetInputAtIndex(currentIndex - 1);
        }
        setValueAtIndex(currentIndex, '');
        break;
      default:
        // Allow pasting (Ctrl+V or Cmd+V)
        if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
          return;
        }

        // Prevent non-numeric characters
        if (/\D/.test(event.key)) {
          event.preventDefault();
        }
        break;
    }
  };

  const currentValue = useMemo(
    () => value.filter((character) => character !== '').join(''),
    [value],
  );

  const isFormValid = currentValue.match(VERIFICATION_CODE_PATTERN);

  const errorText =
    isSubmitted.current && !isFormValid ? t('verificationCodeError') : error;

  useEffect(() => {
    if (isFormValid) {
      isSubmitted.current = true;
      onChange(currentValue);
    }
  }, [currentValue, isFormValid, onChange]);

  // OTP input must always be in LTR
  return (
    <>
      <Box
        dir="ltr"
        ref={containerRef}
        sx={{
          display: 'flex',
          gap: { xs: 0.5, sm: 1, md: 1 },
          alignItems: 'center',
        }}
        onPaste={(event) => {
          event.preventDefault();
          const pastedText = event.clipboardData
            .getData('text/plain')
            .substring(0, INPUT_LENGTH)
            .trim();
          setValue(pastedText.split(''));
        }}
      >
        {INPUTS_ARRAY.map((_, index) => (
          <OutlinedInput
            key={index}
            error={Boolean(errorText)}
            id={String(index)}
            onChange={(event) => {
              event.preventDefault();
              const numericValue = event.target.value.replace(/\D/g, '');
              setValueAtIndex(index, numericValue);
              if (numericValue) {
                targetInputAtIndex(index + 1);
              }
            }}
            onKeyDown={handleKeyDown}
            inputProps={{
              type: 'number',
              inputMode: 'numeric', // for safari
              'data-testid': `otpInput-${index}`,
              value: value[index],
            }}
            sx={InputElementStyle}
            aria-label={`Digit ${index + 1} of OTP`}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          color: (theme) => theme.palette.error.main,
        }}
        data-testid="otpErrorText"
      >
        {errorText}
      </Box>
    </>
  );
};

const InputElementStyle = {
  width: { xs: '40px', sm: '50px', md: '60px' },
  height: { xs: '50px', sm: '60px', md: '70px' },
  borderRadius: '8px',
  border: '1px solid #cccccc',
  fontSize: { xs: '16px', sm: '28px', md: '32px' },
  fontWeight: '400',
  lineHeight: '40px',
  padding: '8px 0',

  '& input[type="number"]': {
    textAlign: 'center',
    padding: { xs: '8px 6px 8px 6px', sm: '16px 14px 16px 14px' },
    MozAppearance: 'textfield', // remove number spinners on ff
  },
  '&:hover': {
    borderColor: '#3399FF',
  },
  // firefox
  '&focus-visible': {
    outline: 0,
  },
  // remove up and down spinners on number input
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      appearance: 'none',
      margin: 0,
    },
};
