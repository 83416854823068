import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  COUNTRY,
  FIRST_NAME,
  LAST_NAME,
  STATE,
  ZIP,
} from '../common/constants';
import * as v from 'valibot';

export const ACCOUNT_NUMBER = 'accountNumber';
export const BANK_ID = 'bankId';

export const DEFAULT_BANK_TRANSFER_FORM_STATE = {
  [ACCOUNT_NUMBER]: '',
  [BANK_ID]: '',
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [ADDRESS_LINE_1]: '',
  [ADDRESS_LINE_2]: '',
  [CITY]: '',
  [COUNTRY]: '',
  [STATE]: '',
  [ZIP]: '',
};

export const BANK_TRANSFER_FORM_SCHEMA = v.object({
  [ACCOUNT_NUMBER]: v.pipe(v.string(), v.nonEmpty()),
  [BANK_ID]: v.pipe(v.string(), v.nonEmpty()),
  [FIRST_NAME]: v.pipe(v.string(), v.nonEmpty()),
  [LAST_NAME]: v.pipe(v.string(), v.nonEmpty()),
  [ADDRESS_LINE_1]: v.pipe(v.string(), v.nonEmpty()),
  [ADDRESS_LINE_2]: v.optional(v.string()),
  [CITY]: v.pipe(v.string(), v.nonEmpty()),
  [COUNTRY]: v.pipe(v.string(), v.nonEmpty()),
  [STATE]: v.pipe(v.string(), v.nonEmpty()),
  [ZIP]: v.pipe(v.string(), v.nonEmpty()),
});

export const BANK_TRANSFER_FORM_KEY = 'hts-bank-transfer-form';
