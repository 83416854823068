import { createFileRoute, redirect } from '@tanstack/react-router';
import { Loading } from '../../common/components/Loading';
import { Logger } from '../../products/cfar/utilities/logger';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';

export const Route = createFileRoute('/v2/_authenticated')({
  beforeLoad: ({ location }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    Logger.setGlobalContext({ session_id: sessionId });

    if (!authentication) {
      throw redirect({
        to: '/v2/authenticate',
        search: {
          redirect: location.href,
        },
      });
    }
  },
  pendingComponent: Loading,
  errorComponent: ErrorComponent,
});
