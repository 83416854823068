import { createFileRoute, useRouter } from '@tanstack/react-router';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';
import { Loading } from '../../common/components/Loading';
import { ErrorCode } from '../../common/constants/errorCode';
import { setDatadogContext } from '../../products/cfar/utilities/setDatadogContext';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';
import { ConfirmationV2 } from '../../products/cfar/components/ConfirmationV2';
import { payoutConversionOptions } from '../../products/cfar/queries/payoutConversionOptions';
import {
  PayoutMethod,
  PayoutMethodVendor,
} from '@hts/partner-airlines-api-client';
import * as v from 'valibot';
import { useEffect } from 'react';
import { HWTransferMethodType } from '../../common/components/hooks/useHyperwallet';
import { BANK_TRANSFER_FORM_SCHEMA } from '../../products/cfar/components/BankTransferForm/constants';

const searchSchema = v.union([
  v.object({
    payout: v.union([
      v.object({
        type: v.literal(PayoutMethod.OriginalFormOfPayment),
        // ofop doesn't have a specific vendor on the backend
        // so hyperwallet and null toke will drive this path
        vendor: v.literal(PayoutMethodVendor.Hyperwallet),
        token: v.null(),
      }),
      v.object({
        type: v.literal(PayoutMethod.PayoutProvider),
        vendor: v.literal(PayoutMethodVendor.Hyperwallet),
        transferMethodType: v.enum(HWTransferMethodType),
        currencyCode: v.string(),
        token: v.string(),
      }),
      v.object({
        type: v.literal(PayoutMethod.PayoutProvider),
        vendor: v.literal(PayoutMethodVendor.BankAccountInformation),
        formData: BANK_TRANSFER_FORM_SCHEMA,
      }),
      v.object({
        type: v.literal(PayoutMethod.PayoutProvider),
        vendor: v.literal(PayoutMethodVendor.Spreedly),
        token: v.string(),
      }),
      v.undefined(), // for masking to work
    ]),
  }),
]);

export type PayoutSchema = v.InferOutput<typeof searchSchema>;

export const Route = createFileRoute('/v2/_authenticated/confirmation')({
  component: () => <Confirmation />,
  pendingComponent: Loading,
  validateSearch: (search: Record<string, unknown>) =>
    v.parse(searchSchema, search),
  loaderDeps: ({ search: { payout } }) => ({
    payout,
  }),
  loader: async ({ context: { queryClient }, deps }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }
    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode),
    );

    setDatadogContext({
      exercise_id: exercise.id,
      contract_id: exercise.contractId,
    });

    if (
      deps.payout?.type === PayoutMethod.PayoutProvider &&
      deps.payout?.vendor === PayoutMethodVendor.Hyperwallet
    ) {
      const payoutConversion = await queryClient.ensureQueryData(
        payoutConversionOptions(
          sessionId,
          exercise.id,
          authentication.verificationCode,
          deps.payout.currencyCode,
        ),
      );
      return {
        exercise,
        payoutAmount: payoutConversion.targetAmount,
        payoutCurrency: payoutConversion.targetCurrency,
        transferMethodType: deps.payout.transferMethodType,
      };
    } else {
      return {
        exercise,
        payoutAmount: exercise.cashRefundAllowance,
        payoutCurrency: exercise.hopperRefundCurrency,
        transferMethodType: undefined, // hw only
      };
    }
  },
  errorComponent: ErrorComponent,
});

function Confirmation() {
  const router = useRouter();

  useEffect(() => {
    // Block the back button by pushing a new history entry
    const handleBackButton = () => {
      router.history.push(window.location.pathname); // Prevent back navigation
    };

    window.history.replaceState(null, '', window.location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [router]);

  return <ConfirmationV2 />;
}
