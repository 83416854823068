import { Partner } from '@hts/common';

const defaultLogo = 'logo-default.svg';

const PartnerLogos = new Map<string, string>([
  [Partner.HtsAirline, defaultLogo],
  [Partner.VirginAustralia, 'logo-va.svg'],
  [Partner.Volaris, 'logo-y4.svg'],
  [Partner.Flair, 'logo-f8.svg'],
  [Partner.AirCanada, 'logo-ac.svg'],
  [Partner.AirAsia, 'logo-ak.svg'],
  [Partner.Jazeera, 'logo-j9.png'],
  [Partner.Flyadeal, 'logo-f3.png'],
]);

function getPartnerLogoUrl(fileName: string) {
  return new URL(`../../assets/logos/${fileName}`, import.meta.url).href;
}

export function iconForPartner(partnerId: string) {
  const fileName = PartnerLogos.get(partnerId) || defaultLogo;
  return getPartnerLogoUrl(fileName);
}
