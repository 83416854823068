import { createFileRoute } from '@tanstack/react-router';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';
import { Loading } from '../../common/components/Loading';
import { ErrorCode } from '../../common/constants/errorCode';
import { setDatadogContext } from '../../products/cfar/utilities/setDatadogContext';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';
import { RecipientFormV2 } from '../../products/cfar/components/RecipientForm/RecipientFormV2';
import { useEffect, useState } from 'react';
import { enUS } from 'date-fns/locale/en-US';
import { DATE_FNS_LOCALES, SupportedLocale } from '../../i18n/constants';
import { usePartnerTranslation } from '../../products/cfar/components/hooks/usePartnerTranslation';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';

export const Route = createFileRoute('/v2/_authenticated/create-recipient')({
  component: RecipientForm,
  pendingComponent: Loading,
  loader: async ({ context: { queryClient } }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }

    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );
    if (exercise.status !== CfarExerciseStatus.Created) {
      throw new Error(ErrorCode.ExerciseAlreadyHasATransaction);
    }

    setDatadogContext({
      exercise_id: exercise.id,
      contract_id: exercise.contractId,
    });

    return { exercise };
  },
  errorComponent: ErrorComponent,
});

function RecipientForm() {
  const { i18n } = usePartnerTranslation();
  const [dateFnsLocale, setDateFnsLocale] = useState(enUS);

  useEffect(() => {
    DATE_FNS_LOCALES[i18n.language as SupportedLocale]().then((localeData) => {
      setDateFnsLocale(localeData[i18n.language]);
    });
  }, [i18n.language]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
    >
      <div data-testid={'recipientForm'}>
        <RecipientFormV2 />
      </div>
    </LocalizationProvider>
  );
}
