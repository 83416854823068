import { createFileRoute, redirect, useRouter } from '@tanstack/react-router';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { cfarEvent } from '../products/cfar/api/cfarEvents';
import { sendVerificationCode } from '../products/cfar/api/sendVerificationCode';
import { Logger } from '../products/cfar/utilities/logger';
import { checkVerificationCode } from '../products/cfar/api/checkVerificationCode';
import { ErrorCode } from '../common/constants/errorCode';
import { ExerciseStepResult } from '@hts/partner-airlines-api-client';
import { AuthenticationForm } from '../common/components/AuthenticationForm';
import { setAuthentication, useCfarStore } from '../products/cfar/store';
import { ErrorComponent } from '../common/components/ErrorComponent';

export const Route = createFileRoute('/authenticate')({
  validateSearch: (search: Record<string, unknown>) => {
    const redirect = String(search['redirect'] || '');
    return { redirect };
  },
  beforeLoad: ({ search }) => {
    if (useCfarStore.getState().authentication) {
      throw redirect({ to: search.redirect });
    }
  },
  component: Authenticate,
  errorComponent: ErrorComponent,
});

function Authenticate() {
  const { sessionId, exerciseId } = useCfarStore();
  const { redirect } = Route.useSearch();
  const router = useRouter();

  useEffect(() => {
    cfarEvent(sessionId, exerciseId, 'cfar_exercise_portal_display');
  }, [exerciseId, sessionId]);

  const sendCodeMutation = useMutation({
    mutationFn: async () => sendVerificationCode(sessionId, exerciseId),
    onSuccess: () => {
      cfarEvent(sessionId, exerciseId, 'cfar_exercise_verification_sent');
    },
    onError: (error) => {
      cfarEvent(
        sessionId,
        exerciseId,
        'cfar_exercise_verification_sent',
        ExerciseStepResult.Failure
      );
      Logger.error('Failed at sendVerificationCode', undefined, error);
    },
  });

  const checkVerificationCodeMutation = useMutation({
    mutationFn: async (verificationCode: string) =>
      checkVerificationCode(sessionId, exerciseId, verificationCode),
    onSuccess: (data, verificationCode) => {
      if (!data.compliant) {
        throw Error(ErrorCode.InvalidState);
      }

      cfarEvent(
        sessionId,
        exerciseId,
        'cfar_exercise_verification_complete',
        ExerciseStepResult.Success
      );

      const emailAddress = sendCodeMutation.data?.anonymizedEmailAddress || '';
      setAuthentication({ verificationCode, emailAddress });
    },
    throwOnError: false,
  });

  const followRedirect = useCallback(async () => {
    router.history.push(redirect);
    await router.invalidate();
  }, [router, redirect]);

  useEffect(() => {
    if (checkVerificationCodeMutation.isSuccess) {
      followRedirect();
    }
  }, [checkVerificationCodeMutation.isSuccess, followRedirect]);

  if (checkVerificationCodeMutation.isError) {
    cfarEvent(
      sessionId,
      exerciseId,
      'cfar_exercise_verification_complete',
      ExerciseStepResult.Failure
    );
    Logger.error(
      'Failed at checkVerificationCode',
      undefined,
      checkVerificationCodeMutation.error
    );
  }

  return (
    <AuthenticationForm
      checkVerificationCode={checkVerificationCodeMutation}
      sendVerificationCode={sendCodeMutation}
    />
  );
}
