import { Logger } from './logger';

function validatePartnerUrl(url: string): boolean {
  const partnerRedirectUrlDomains = [
    'volaris.com',
    'virginaustralia.com',
    'aircanada.com',
    'flyflair.com',
    'airasia.com',
    'jazeeraairways.com',
    'hopper.com',
  ];

  try {
    // Extract domain from URL
    const urlObj = new URL(url);
    const domain = urlObj.hostname.toLowerCase();

    // Check if domain matches or is subdomain of allowed domains
    const matched = partnerRedirectUrlDomains.some(
      (allowedDomain) =>
        domain === allowedDomain || domain.endsWith(`.${allowedDomain}`),
    );

    if (!matched) {
      Logger.error(`Redirection: Unsupported URL domain: ${domain}`);
      return false;
    }
    return true;
  } catch (error) {
    // URL parsing failed
    Logger.error(`Redirection: Invalid URL format: ${url}`);
    return false;
  }
}

export function createRedirectUrl(url: string, redirectionToken?: string) {
  const decodedUrl = decodeURIComponent(url);

  // Validate the URL
  if (!validatePartnerUrl(decodedUrl)) {
    return '';
  }

  if (redirectionToken) {
    // Concatenate the redirection token to the given url
    return decodedUrl + '&redirectionToken=' + redirectionToken;
  }

  return decodedUrl;
}
