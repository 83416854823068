import { Box, CircularProgress, Stack, Typography } from '@mui/material';

type LoadingProps = {
  loadingMessage?: string;
};

export function Loading({ loadingMessage }: LoadingProps) {
  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={'50%'}
      spacing={'54px'}
      minHeight={'100px'}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
      {loadingMessage && (
        <Typography variant="body2">{loadingMessage}</Typography>
      )}
    </Stack>
  );
}
