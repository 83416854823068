import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    h2: {
      ...typographyDefaults.h2,
      fontWeight: 500,
    },
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 500,
    },
    body1: {
      ...typographyDefaults.body1,
      fontWeight: 400,
    },
    fontFamily: 'J9',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#004A97',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
          fontWeight: 500,
          borderRadius: '32px',
          boxShadow: 'none',
        },
        text: {
          color: '#004A97',
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    text: {
      primary: '#061C2F',
      contrastText: '#ffffff',
    },
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#0273c1',
        navbarContrastText: '#ffffff',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#ffc803',
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const j9Theme = responsiveFontSizes(createTheme(theme2));
