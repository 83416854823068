import { getClient } from './getClient';

export async function cfarExercise(
  sessionId: string,
  verificationCode: string
) {
  const client = getClient(sessionId);
  return client.getCustomerCfarExercises({
    verificationCode,
  });
}
