import * as v from 'valibot';
import { Partner } from '@hts/common';
import {
  setPartnerCreatedExerciseFlowParams,
  setHTSCreatedExerciseFlowParams,
  useCfarStore,
  clearAuthentication,
  getSessionId,
} from '../../products/cfar/store';
import { MOCK_ENABLED } from '../../config';
import { partnerId as partnerIdStorage } from '../../products/cfar/api/mock/storage';

const paramSchema = v.object({
  hSessionId: v.pipe(v.string(), v.nonEmpty()),
  exerciseId: v.pipe(v.string(), v.nonEmpty()),
  callbackUrl: v.optional(v.string()),
  redirectbackUrl: v.optional(v.string()),
});

// Param schema for flow where exercise is created on landing
const htsCreatedExerciseFlowParamSchema = v.object({
  contractId: v.pipe(v.string(), v.nonEmpty()),
  partnerId: v.pipe(v.string(), v.nonEmpty()),
  callbackUrl: v.optional(v.string()),
  redirectbackUrl: v.optional(v.string()),
});

export async function sanitizeUrl() {
  const url = new URL(window.location.href.split('#/').join(''));
  const paramsObject = Object.fromEntries(url.searchParams);

  // Try partner created exercise flow first
  const result = v.safeParse(paramSchema, paramsObject);
  if (result.success) {
    const {
      hSessionId: sessionId,
      exerciseId,
      callbackUrl,
      redirectbackUrl: redirectBackUrl,
    } = result.output;

    // Clear authentication if session id changes cause
    // the backend invalidates the verification code.
    // Forces the user to re-authenticate.
    const oldSessionId = getSessionId();
    if (oldSessionId && oldSessionId !== sessionId) {
      clearAuthentication();
    }

    setPartnerCreatedExerciseFlowParams({
      sessionId,
      redirectBackUrl,
      callbackUrl,
      exerciseId,
    });
  } else {
    // Try HTS created exercise flow
    const contractResult = v.safeParse(
      htsCreatedExerciseFlowParamSchema,
      paramsObject
    );
    if (contractResult.success) {
      const {
        contractId,
        partnerId,
        callbackUrl,
        redirectbackUrl: redirectBackUrl,
      } = contractResult.output;
      setHTSCreatedExerciseFlowParams({
        contractId,
        partnerId: partnerId as Partner,
        callbackUrl,
        redirectBackUrl,
      });
      if (MOCK_ENABLED) {
        partnerIdStorage.update(() => partnerId as Partner);
      }
    }
  }

  // Always clean the URL
  url.hash = '';
  url.search = '';
  window.history.replaceState({}, '', url.toString());
}
