import {
  createFileRoute,
  ErrorComponent,
  useNavigate,
} from '@tanstack/react-router';
import { usePartnerTranslation } from '../../products/cfar/components/hooks/usePartnerTranslation';
import { StepHeader } from '../../common/components/StepHeader';
import { Box, Button } from '@mui/material';
import { useErrorMessage } from '../../common/components/hooks/useErrorMessage';
import { ErrorCode } from '../../common/constants/errorCode';
import { Loading } from '../../common/components/Loading';
import { useCfarStore } from '../../products/cfar/store';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';
import { getPayoutUrl } from '../../products/cfar/utilities/getPayoutUrl';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';
import { useQuery } from '@tanstack/react-query';
import { useAuthentication } from '../../products/cfar/components/hooks/useAuthentication';

export const Route = createFileRoute('/v2/_authenticated/payment-error')({
  component: PayoutFailedErrorDisplay,
  loader: async ({ context: { queryClient } }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }

    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );
    if (exercise.status !== CfarExerciseStatus.Created) {
      throw new Error(ErrorCode.ExerciseAlreadyHasATransaction);
    }
    return { sessionId, authentication };
  },
  errorComponent: ErrorComponent,
  pendingComponent: Loading,
});

function PayoutFailedErrorDisplay() {
  const { t } = usePartnerTranslation();
  const navigate = useNavigate();
  const { sessionId, partnerId } = useCfarStore();
  const { verificationCode } = useAuthentication();
  const { data: exercise } = useQuery(
    exerciseOptions(sessionId, verificationCode)
  );

  const onClickContinue = () => {
    const payoutVendorUrl = getPayoutUrl(partnerId, exercise?.payoutVendors);
    navigate({ to: payoutVendorUrl });
  };

  const message = useErrorMessage(ErrorCode.PayoutFailed);

  return (
    <StepHeader
      title={t('ERROR_FAILED_PAYOUT_TITLE')}
      message={message}
      children={
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            onClick={onClickContinue}
            data-testid="continue"
            size="large"
            color="secondary"
          >
            {t('CFAR_EXERCISE_FLOW_V2.SUMMARY.NEXT_STEP_BUTTON')}
          </Button>
        </Box>
      }
    />
  );
}
