import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Partner } from '@hts/common';
import { useCfarStore } from '../../products/cfar/store';

function getImageUrl(lang: string, partnerId: string) {
  const logoPath =
    Partner.VirginAustralia === partnerId
      ? 'powered-by-hts-logo'
      : 'provided-by-hts-logo';

  return new URL(`../../assets/${logoPath}/${lang}.svg`, import.meta.url).href;
}

export const Footer = () => {
  const { i18n } = useTranslation();
  const partnerId = useCfarStore(({ partnerId }) => partnerId);

  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        height: '64px',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        bgcolor: 'white',
        boxShadow: 2,
      }}
    >
      <a
        style={{ display: 'flex' }}
        href="https://hts.hopper.com/"
        target="_blank"
        rel="noreferrer"
      >
        <Box
          component="img"
          src={getImageUrl(i18n.language, partnerId)}
          sx={{
            margin: 'auto',
            background: 'white',
            padding: '4px 16px',
            borderRadius: '16px',
          }}
        />
      </a>
    </Box>
  );
};
