import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Partner } from '@hts/common';

export const STORAGE_KEY = 'hts-air-cfar-exercise';

interface State {
  isFlowCompleted: boolean;
  partnerId: Partner;
  contractId?: string;
  sessionId: string;
  exerciseId: string;
  redirectBackUrl?: string;
  callbackUrl?: string;
  authentication?: {
    verificationCode: string;
    emailAddress: string;
  };
}

const INITIAL_STATE: State = {
  exerciseId: '',
  sessionId: '',
  partnerId: Partner.HtsAirline,
  isFlowCompleted: false,
};

export const useCfarStore = create(
  persist(() => INITIAL_STATE, {
    name: STORAGE_KEY,
    storage: createJSONStorage(() => sessionStorage),
  })
);

export const getSessionId = () => useCfarStore.getState().sessionId;

export const setPartnerId = (partnerId: Partner) =>
  useCfarStore.setState({ partnerId });

export const setIsFlowCompleted = (isFlowCompleted: boolean) =>
  useCfarStore.setState({ isFlowCompleted });

export const setPartnerCreatedExerciseFlowParams = (
  data: Pick<
    State,
    'callbackUrl' | 'exerciseId' | 'sessionId' | 'redirectBackUrl'
  >
) => useCfarStore.setState(data);

export const setHTSCreatedExerciseFlowParams = (
  data: Pick<
    State,
    'contractId' | 'partnerId' | 'callbackUrl' | 'redirectBackUrl'
  >
) => useCfarStore.setState(data);

export const clearAuthentication = () =>
  useCfarStore.setState({ authentication: undefined });

export const setAuthentication = (authentication: State['authentication']) =>
  useCfarStore.setState({ authentication });

export const setExerciseId = (exerciseId: string) =>
  useCfarStore.setState({ exerciseId });

export const setSessionId = (sessionId: string) =>
  useCfarStore.setState({ sessionId });
