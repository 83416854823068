import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const y4BrandColor = '#a12885';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 700,
    },
    fontFamily: 'Avenir',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: y4BrandColor,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#000000',
        navbarContrastText: '#ffffff',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: y4BrandColor,
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const y4Theme = responsiveFontSizes(createTheme(theme2));
