import { DEFAULT_NAMESPACE } from '../constants';
import { Partner } from '@hts/common';

const DEFAULT_NAMESPACES = [DEFAULT_NAMESPACE];

const CUSTOM_PARTNERS = [
  Partner.AirAsia,
  Partner.VirginAustralia,
  Partner.Flair,
  Partner.Flyadeal,
];

const partnerNamespace = {
  [Partner.AirAsia]: 'air_asia',
  [Partner.VirginAustralia]: 'virgin_australia',
  [Partner.Flair]: 'flair',
  [Partner.Flyadeal]: 'flyadeal',
} as Record<Partner, string>;

export function namespaceForPartner(id: Partner): string[] {
  if (!CUSTOM_PARTNERS.includes(id)) {
    return DEFAULT_NAMESPACES;
  }
  return [partnerNamespace[id], DEFAULT_NAMESPACE];
}
