import { getClient } from './getClient';

export async function payoutCurrencyConversion(
  sessionId: string,
  exerciseId: string,
  verificationCode: string,
  targetCurrency: string
) {
  const client = getClient(sessionId);
  return client.getCustomerCfarExercisesIdPayoutCurrencyConversion({
    id: exerciseId,
    verificationCode,
    targetCurrency,
  });
}
