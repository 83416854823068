import { queryOptions } from '@tanstack/react-query';
import { payoutCurrencyConversion } from '../api/payoutCurrencyConversion';

export const payoutConversionOptions = (
  sessionId: string,
  exerciseId: string,
  verificationCode: string,
  transferMethodCurrency: string
) =>
  queryOptions({
    queryKey: ['currencyConversion'],
    queryFn: () =>
      payoutCurrencyConversion(
        sessionId,
        exerciseId,
        verificationCode,
        transferMethodCurrency
      ),
  });
