import { Theme } from '@mui/material/styles';
import { Partner } from '@hts/common';
import { legacyDefaultTheme, legacyVaTheme, legacyY4Theme } from '../../themes';

import {
  defaultTheme,
  vaTheme,
  y4Theme,
  f8Theme,
  acTheme,
  akTheme,
  j9Theme,
  f3Theme,
} from '@hts/partner-themes';

const PartnerThemes = new Map<Partner, Theme>([
  [Partner.HtsAirline, defaultTheme],
  [Partner.VirginAustralia, vaTheme],
  [Partner.Volaris, y4Theme],
  [Partner.Flair, f8Theme],
  [Partner.AirCanada, acTheme],
  [Partner.AirAsia, akTheme],
  [Partner.Jazeera, j9Theme],
  [Partner.Flyadeal, f3Theme],
]);

const LegacyPartnerThemes = new Map<Partner, Theme>([
  [Partner.HtsAirline, legacyDefaultTheme],
  [Partner.VirginAustralia, legacyVaTheme],
  [Partner.Volaris, legacyY4Theme],
]);

export function themeForPartnerId(
  partnerId: string,
  useRedesignThemes = false
): Theme {
  if (useRedesignThemes) {
    return PartnerThemes.get(partnerId as Partner) || defaultTheme;
  }

  return LegacyPartnerThemes.get(partnerId as Partner) || legacyDefaultTheme;
}
