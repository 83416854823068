import { Box, SxProps } from '@mui/material';
import { Partner } from '@hts/common';
import { iconForPartner } from '../../../common/utilities/iconForPartner';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/system';

const maxHeight = '64px';

const renderImage = (partner: Partner) => (
  <Box
    component="img"
    sx={partnerLogoStyles[partner] || partnerLogoStyles[Partner.HtsAirline]}
    onError={(e) => (e.currentTarget.style.display = 'none')}
    src={iconForPartner(partner)}
  />
);

const partnerLogoStyles: Record<string, SxProps<Theme>> = {
  [Partner.AirCanada]: {
    width: { xs: '130px', sm: '100%' },
    maxHeight,
  },
  [Partner.HtsAirline]: {
    width: { xs: '100px', sm: '120px' },
    maxHeight,
  },
  [Partner.Flair]: {
    width: { xs: '100px', sm: '120px' },
    maxHeight,
  },
  [Partner.AirAsia]: {
    width: { xs: '120px', sm: '100%' },
    maxHeight,
  },
  [Partner.Volaris]: {
    width: '100%',
    maxHeight,
  },
  [Partner.VirginAustralia]: {
    width: '100%',
    maxHeight,
  },
  [Partner.Jazeera]: {
    maxWidth: { xs: '120px', sm: '150px' },
    maxHeight,
  },
  [Partner.Flyadeal]: {
    width: { xs: '100px', sm: '120px' },
    maxHeight,
  },
};

const CLOSE_ICON_DIMENSIONS = { xs: '15px', sm: '20px' };

export const PartnerXHtsLogo = ({ partnerId }: { partnerId: Partner }) => {
  const isNotCobranded = [
    Partner.VirginAustralia,
    Partner.Volaris,
    Partner.HtsAirline,
    Partner.Jazeera,
  ].includes(partnerId);

  return (
    <Box display="flex" flexDirection="row" gap={{ xs: 1, sm: 2 }}>
      {renderImage(partnerId)}
      {!isNotCobranded && (
        <>
          <Box
            sx={{
              display: 'grid',
              alignContent: 'center',
            }}
          >
            <CloseIcon
              sx={{
                height: CLOSE_ICON_DIMENSIONS,
                width: CLOSE_ICON_DIMENSIONS,
                color: '#3F4041',
              }}
            />
          </Box>
          {renderImage(Partner.HtsAirline)}
        </>
      )}
    </Box>
  );
};
