import { createFileRoute } from '@tanstack/react-router';
import { Loading } from '../../common/components/Loading';
import { ErrorCode } from '../../common/constants/errorCode';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';
import { SpreedlyForm } from '../../products/cfar/components/SpreedlyForm/SpreedlyForm';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';

export const Route = createFileRoute('/v2/_authenticated/card-payout-form')({
  component: () => <Form />,
  pendingComponent: Loading,
  loader: async ({ context: { queryClient } }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }

    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );
    if (exercise.status !== CfarExerciseStatus.Created) {
      throw new Error(ErrorCode.ExerciseAlreadyHasATransaction);
    }
    return { exercise };
  },
  errorComponent: ErrorComponent,
});

function Form() {
  return (
    <div data-testid={'spreedlyForm'}>
      <SpreedlyForm />
    </div>
  );
}
