import { useState } from 'react';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { OTPInput } from './OTPInput';
import { EXERCISE_COMMON_NAMESPACE } from '../locales/constants';

const CONTROL_MAX_WIDTH = [undefined, '250px'];

type SendCodeResponse = { anonymizedEmailAddress: string };

type Props = {
  contactFormUrl: string;
  sendVerificationCode: UseMutationResult<SendCodeResponse, Error, void>;
  checkVerificationCode: UseMutationResult<void, Error, string>;
};

export function AuthenticationForm({
  sendVerificationCode,
  checkVerificationCode,
  contactFormUrl,
}: Props) {
  const { t } = useTranslation(EXERCISE_COMMON_NAMESPACE);
  const codeSent = sendVerificationCode.isSuccess;
  const emailAddress = sendVerificationCode.data?.anonymizedEmailAddress || '';
  const subTitle = codeSent
    ? t('sendVerificationInfo', {
        email: emailAddress,
      })
    : t('checkVerificationInfo');

  const [otp, setOtp] = useState('');

  const verificationCodeError = checkVerificationCode.error ? (
    <Trans
      ns={EXERCISE_COMMON_NAMESPACE}
      i18nKey={'contactSupportMessage'}
      components={[
        <Link
          href={contactFormUrl}
          target="_blank"
          rel="noopener"
          color="error"
        />,
      ]}
    />
  ) : undefined;

  const onSubmit = () => {
    checkVerificationCode.mutate(otp);
  };

  const verificationForm = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      width={'100%'}
    >
      <OTPInput onChange={setOtp} error={verificationCodeError} />

      <Button
        data-testid="continue"
        variant="contained"
        fullWidth
        onClick={onSubmit}
        disabled={!otp || checkVerificationCode.isPending}
        sx={{
          maxWidth: CONTROL_MAX_WIDTH,
        }}
        color="secondary"
      >
        {t('continue')}
      </Button>
      <Typography
        sx={{
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Trans
          ns={EXERCISE_COMMON_NAMESPACE}
          i18nKey="resendVerificationCode"
          components={{
            button: (
              <Button
                data-testid="resendCode"
                onClick={(e) => {
                  e.preventDefault();
                  setOtp('');
                  sendVerificationCode.mutate();
                  checkVerificationCode.reset();
                }}
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: 1.25,
                  width: 'fit-content',
                  borderRadius: 0,
                  px: 0.25,
                }}
                variant="text"
                color="secondary"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 3,
        maxWidth: [undefined, '800px'],
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 0,
        }}
      >
        <Typography variant="h2" color="textHeader">
          {t('checkVerificationTitle')}
        </Typography>
        <Typography>{subTitle}</Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          {codeSent && verificationForm}

          {!codeSent && (
            <Button
              data-testid="sendCode"
              variant={'contained'}
              fullWidth
              sx={{
                maxWidth: CONTROL_MAX_WIDTH,
              }}
              onClick={() => sendVerificationCode.mutate()}
              disabled={
                checkVerificationCode.isPending ||
                sendVerificationCode.isPending ||
                checkVerificationCode.isSuccess
              }
              color="secondary"
            >
              {t('sendVerificationCode')}
            </Button>
          )}

          {sendVerificationCode.error && t('defaultError')}
        </Box>
      </Box>
    </Paper>
  );
}
