import { createFileRoute } from '@tanstack/react-router';
import { BookingSummaryV2 } from '../../products/cfar/components/BookingSummaryV2';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';
import { Loading } from '../../common/components/Loading';
import { ErrorCode } from '../../common/constants/errorCode';
import { setDatadogContext } from '../../products/cfar/utilities/setDatadogContext';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';

export const Route = createFileRoute('/v2/_authenticated/summary')({
  component: Summary,
  pendingComponent: Loading,
  loader: async ({ context: { queryClient } }) => {
    const { sessionId, authentication } = useCfarStore.getState();
    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }

    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );
    if (exercise.status !== CfarExerciseStatus.Created) {
      throw new Error(ErrorCode.ExerciseAlreadyHasATransaction);
    }

    setDatadogContext({
      exercise_id: exercise.id,
      contract_id: exercise.contractId,
    });

    return { exercise };
  },
  errorComponent: ErrorComponent,
});

function Summary() {
  return (
    <div data-testid={'summary'}>
      <BookingSummaryV2 />
    </div>
  );
}
