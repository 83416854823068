export const typographyDefaults = {
  fontSize: 14,
  h1: {
    fontSize: '2.8rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: 1.25,
  },
  body1: {
    fontSize: '14px',
    lineHeight: 1.53,
  },
};

export const componentDefaults = {
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px var(--palette-FilledInput-bg) inset',
          WebkitTextFillColor: 'var(--palette-text-primary)',
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        variants: [],
        fontSize: '16px',
        lineHeight: 1.25,
        height: '48px',
        borderRadius: '4px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
        border: '1px solid #DDDDDD',
      },
    },
  },
};
