import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createRouteMask,
  createRouter,
} from '@tanstack/react-router';
import i18n from 'i18next';
import fetchBackend from 'i18next-fetch-backend';

// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { CssBaseline } from '@mui/material';
import { initReactI18next } from 'react-i18next';
import { config } from './i18n/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loading } from './common/components/Loading';
import { MOCK_ENABLED } from './config';
import { ErrorComponent } from './common/components/ErrorComponent';
import { startRumSession } from './products/cfar/utilities/startRumSession';
import { ErrorDisplay } from './common/components/ErrorDisplay';
import { ErrorCode } from './common/constants/errorCode';
import { sanitizeUrl } from './common/utilities/sanitizeUrl';

import './styles.scss';
import { parseBaseUrl } from './common/utilities/parseBaseUrl';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
    },
    mutations: {
      throwOnError: true,
    },
  },
});

const paymentRouteMask = createRouteMask({
  routeTree,
  from: '/v2/payment',
  to: '/v2/payment',
  search: { recipient: undefined },
});

const confirmationRouteMask = createRouteMask({
  routeTree,
  from: '/v2/confirmation',
  to: '/v2/confirmation',
  search: { payout: undefined },
});

// Create a new router instance
const router = createRouter({
  basepath: `/${parseBaseUrl()}`,
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: () => <Loading />,
  defaultErrorComponent: ErrorComponent,
  defaultNotFoundComponent: () => (
    <ErrorDisplay errorCode={ErrorCode.InvalidSearchParams} />
  ),
  routeMasks: [paymentRouteMask, confirmationRouteMask],
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(fetchBackend)
  .init(config);

const initializeWorker = async () => {
  if (MOCK_ENABLED) {
    const { worker } = await import('./products/cfar/api/mock/browser');
    return worker.start({
      onUnhandledRequest: (request) => {
        if (request.url.includes('browser-intake-us5-datadoghq.com')) {
          return;
        }
      },
    });
  }
  return Promise.resolve();
};

async function initialize() {
  // Remove hash and search and initialize store data
  sanitizeUrl();
  await initializeWorker();
  startRumSession();
}

function App() {
  return (
    <StrictMode>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} context={{ queryClient }} />
      </QueryClientProvider>
    </StrictMode>
  );
}

// Render the app
initialize().then(() => {
  const rootElement = document.getElementById('app')!;
  if (!rootElement.innerHTML) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<App />);
  }
});
