import { useSuspenseQuery } from '@tanstack/react-query';
import { FeatureFlag } from '../../featureFlags';
import {
  sessionOptions,
  SupportedExperiments,
} from '../../queries/sessionOptions';

export function useFeatureFlag<T extends FeatureFlag>(
  sessionId: string,
  featureFlag: T
): SupportedExperiments[T] {
  const {
    data: { experiments },
  } = useSuspenseQuery(sessionOptions(sessionId));
  return experiments[featureFlag];
}
