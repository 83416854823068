import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { Partner } from '@hts/common';
import { useCfarStore } from '../../store';
import { createRedirectUrl } from '../../utilities/createRedirectUrl';
import { Logger } from '../../utilities/logger';
import { sessionOptions } from '../../queries/sessionOptions';
import { exerciseOptions } from '../../queries/exerciseOptions';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';
import { useEffect, useState } from 'react';

const VA_REDIRECT_BACK_URL =
  'https://www.virginaustralia.com/au/en/book/manage-booking/cancel-for-any-reason/';

const getExerciseCompleted = async (
  queryClient: QueryClient,
  verificationCode: string | undefined,
  sessionId: string
) => {
  if (verificationCode) {
    const exercise = await queryClient.ensureQueryData({
      ...exerciseOptions(sessionId, verificationCode),
    });
    return exercise?.status === CfarExerciseStatus.Confirmed;
  }
  return false;
};

export function usePartnerRedirect() {
  const { sessionId, redirectBackUrl, isFlowCompleted, authentication } =
    useCfarStore(
      ({ sessionId, redirectBackUrl, isFlowCompleted, authentication }) => ({
        sessionId,
        redirectBackUrl,
        isFlowCompleted,
        authentication,
      })
    );
  const { isSuccess, data } = useQuery({
    ...sessionOptions(sessionId),
    throwOnError: false,
  });
  const queryClient = useQueryClient();
  const [isExerciseComplete, setIsExerciseComplete] = useState(false);

  // if user is not authenticated, we should not check if the
  // exercise is complete and display the partner redirect button
  useEffect(() => {
    const checkExerciseComplete = async () => {
      const result = await getExerciseCompleted(
        queryClient,
        authentication?.verificationCode,
        sessionId
      );
      setIsExerciseComplete(result);
    };
    checkExerciseComplete();
  }, [queryClient, authentication?.verificationCode, sessionId]);

  if (isFlowCompleted || isExerciseComplete || !isSuccess) {
    return null;
  }

  const { partnerId, partnerName } = data;

  let targetUrl = redirectBackUrl;

  if (partnerId === Partner.VirginAustralia) {
    targetUrl = VA_REDIRECT_BACK_URL;
  }

  if (!targetUrl) {
    return null;
  }

  return {
    handler: () => {
      try {
        Logger.info(
          `redirecting user to partner-provided redirectbackUrl: ${targetUrl}`
        );
        window.location.href = createRedirectUrl(targetUrl);
      } catch (error) {
        Logger.warn('Cannot decode the URI: ' + targetUrl);
      }
    },
    partnerName,
  };
}
