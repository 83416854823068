import { subYears } from 'date-fns';

export const FIRST_NAME = 'firstName';
export const MIDDLE_NAME = 'middleName';
export const LAST_NAME = 'lastName';
export const DATE_OF_BIRTH = 'dateOfBirth';
export const ADDRESS_LINE_1 = 'addressLine1';
export const ADDRESS_LINE_2 = 'addressLine2';
export const CITY = 'city';
export const COUNTRY = 'country';
export const STATE = 'state';
export const ZIP = 'zip';

export const MAX_BIRTH_DATE = subYears(new Date(), 18); // Minimum 18 years
