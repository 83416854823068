import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const vaBrandColor = '#e10a0a';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 700,
    },
    fontFamily: 'Montserrat',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: vaBrandColor,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
          borderRadius: '14px',
          fontWeight: 700,
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#ffffff',
        navbarContrastText: '#000000',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: vaBrandColor,
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const vaTheme = responsiveFontSizes(createTheme(theme2));
