import { createFileRoute } from '@tanstack/react-router';
import { exerciseOptions } from '../../products/cfar/queries/exerciseOptions';
import { Loading } from '../../common/components/Loading';
import { ErrorCode } from '../../common/constants/errorCode';
import { setDatadogContext } from '../../products/cfar/utilities/setDatadogContext';
import { ErrorComponent } from '../../common/components/ErrorComponent';
import { useCfarStore } from '../../products/cfar/store';
import { PaymentV2, Recipient } from '../../products/cfar/components/PaymentV2';
import { QueryClient } from '@tanstack/react-query';
import { CfarExerciseStatus } from '@hts/partner-airlines-api-client';

export const Route = createFileRoute('/v2/_authenticated/payment')({
  component: PaymentForm,
  pendingComponent: Loading,
  validateSearch: (
    search: Record<string, unknown>
  ): { recipient?: Recipient } => {
    return {
      recipient: search.recipient as Recipient,
    };
  },
  loader: async ({
    context: { queryClient },
  }: {
    context: { queryClient: QueryClient };
  }) => {
    const { sessionId, authentication } = useCfarStore.getState();

    if (!authentication) {
      throw new Error(ErrorCode.NotAuthorized);
    }

    const exercise = await queryClient.ensureQueryData(
      exerciseOptions(sessionId, authentication.verificationCode)
    );
    if (exercise.status !== CfarExerciseStatus.Created) {
      throw new Error(ErrorCode.ExerciseAlreadyHasATransaction);
    }

    setDatadogContext({
      exercise_id: exercise.id,
      contract_id: exercise.contractId,
    });

    return { exercise, verificationCode: authentication.verificationCode };
  },
  errorComponent: ErrorComponent,
});

function PaymentForm() {
  const { recipient } = Route.useSearch();

  if (recipient === undefined) {
    return null;
  }

  return <PaymentV2 recipient={recipient} />;
}
