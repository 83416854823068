import { createFileRoute, redirect } from '@tanstack/react-router';
import { ErrorComponent } from '../common/components/ErrorComponent';
import { useCfarStore } from '../products/cfar/store';
import { ErrorCode } from '../common/constants/errorCode';
import { sessionOptions } from '../products/cfar/queries/sessionOptions';
import { QueryClient } from '@tanstack/react-query';
import { FeatureFlag } from '../products/cfar/featureFlags';

const redirectToExercise = (useCfarRedesign = false) => {
  const targetRoute = useCfarRedesign ? '/v2/summary' : '/exercise';
  throw redirect({
    to: targetRoute,
    replace: true,
  });
};

interface Experiments {
  [key: string]: boolean;
}

const getExperiments = async (
  queryClient: QueryClient,
  sessionId: string
): Promise<Experiments> => {
  if (!sessionId) return {};
  const { experiments } = await queryClient.ensureQueryData(
    sessionOptions(sessionId)
  );
  return experiments;
};

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context: { queryClient } }) => {
    const { sessionId, exerciseId } = useCfarStore.getState();
    if (!sessionId || !exerciseId) {
      throw new Error(ErrorCode.InvalidSearchParams);
    }

    const experiments = await getExperiments(queryClient, sessionId);
    redirectToExercise(experiments[FeatureFlag.CfarUseExerciseRedesign]);
  },
  errorComponent: ErrorComponent,
});
