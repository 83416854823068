import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const f8BrandColor = '#801CC1';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    h2: {
      ...typographyDefaults.h2,
      fontWeight: 900,
    },
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 900,
    },
    fontFamily: 'AvertaStd',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: f8BrandColor,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
          fontWeight: 900,
          borderRadius: '40px',
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#ffffff',
        navbarContrastText: '#000000',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: f8BrandColor,
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const f8Theme = responsiveFontSizes(createTheme(theme2));
