import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const theme = createTheme({
  typography: {
    ...typographyDefaults,
    h2: {
      ...typographyDefaults.h2,
      fontFamily: 'OpenSans',
      fontWeight: 700,
    },
    body2: {
      ...typographyDefaults.body1,
      fontWeight: 700,
    },
    fontFamily: 'Nunito Sans',
  },
  components: {
    ...componentDefaults,
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#D0DD47',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...componentDefaults.MuiButton.styleOverrides.root,
          fontWeight: 700,
          borderRadius: '32px',
          boxShadow: 'none',
          color: '#4D2F69',
        },
        text: {
          color: '#4D2F69',
        },
      },
    },
  },
});

const theme2 = createTheme(theme, {
  cssVariables: { cssVarPrefix: '' },
  palette: {
    text: {
      primary: '#111111',
    },
    primary: theme.palette.augmentColor({
      color: {
        main: '#3F4041',
        navbarColor: '#ffffff',
      },
      name: 'primary',
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#D0DD47',
      },
      name: 'secondary',
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#f23221',
      },
      name: 'error',
    }),
  },
});

export const f3Theme = responsiveFontSizes(createTheme(theme2));
